/**
 * Copyright Han Lee <hanlee.dev@gmail.com> and other contributors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Panose from './panose'

class FontFace {
  name: string = ''

  alternative: string = ''

  default: string = ''

  panose: Panose | null = null

  getFontFamily(): string {
    const result = [`${this.name}`]

    if (this.alternative) {
      result.push(`"${this.alternative}"`)
    }

    if (this.default) {
      result.push(`"${this.default}"`)
    }

    if (this.panose) {
      const panoseFontFamily = this.panose.getFontFamily()
      result.push(panoseFontFamily)
    }

    return result.join(',')
  }
}

export default FontFace
