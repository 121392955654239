/**
 * Copyright Han Lee <hanlee.dev@gmail.com> and other contributors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { CommonCtrlID } from '../constants/ctrlID'
import { Control } from '../models/controls'
import TableControl from '../models/controls/table'
import { ShapeControls, PictureControl } from '../models/controls/shapes'

export function isTable(control: Control): control is TableControl {
  return control.id === CommonCtrlID.Table
}

export function isShape(control: Control): control is ShapeControls {
  return control.id === CommonCtrlID.GenShapeObject
}

export function isPicture(control: ShapeControls): control is PictureControl {
  return control.type === CommonCtrlID.Picture
}
